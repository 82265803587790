import React, { useEffect } from 'react';
import { Box } from 'src/components/Box';
import { ContactForm } from 'src/components/ContactForm';
import { ContentWidth } from 'src/components/ContentWidth';
import { Layout } from 'src/components/Layout';
import { useNavigate } from "@reach/router"
const querystring = require('querystring');

export default function contact() {
    const navigate = useNavigate();
    let source = 'page';
    if (
        typeof window !== 'undefined' &&
        window.location &&
        window.location.search
    ) {
        const query = querystring.parse(
            window.location.search.split('?').join('')
        );

        if (query && query.source) {
            source = query.source;
        }
    }

    useEffect(() => {
        const Intercom = window['Intercom']

        Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "awlgtkwp"
        });

        Intercom("show");
    }, []);

    return (
        <Layout
            pageTitleFull="Contact Us | Audentio Community"
            siteDescription="Looking for help with your online community? Contact us."
        >
            <ContentWidth
                maxWidth="containers.header"
                backgroundColor="red"
                flexGrow="1"
            >
                <Box mx="auto" mt="20%">
                    {/* intercom */}
                </Box>
            </ContentWidth>
        </Layout>
    );
}
